import React, { createContext, useState, useRef, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import RequestDemoSection from "../components/RequestDemoSection";
import { isBrowser } from "../utils/common";

let Toast = {};
if(isBrowser())
Toast = require('bootstrap').Toast;

const defaultContextValue = {
  onOpenModal: () => {},
  showToast: () => {},
};
const LayoutContext = createContext(defaultContextValue);

function LayoutProvider({ children }) {
  const [open, setOpen] = useState(false);
  var [toast, setToast] = useState(true);
  const [toastDetails, setToastDetails] = useState({ type: "", message: "" });
  const toastRef = useRef();

  useEffect(() => {
    if (!isBrowser()) return;
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);

    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { autohide: true });
      // hide after init
      bsToast.hide();
      hideToast();
    } else {
      // toggle
      if(toast){
        bsToast.show()
      } else {
        bsToast.hide();
        // hideToast();
      }
      // toast ? bsToast.show() : bsToast.hide();
    }
  });

  useEffect(() => {
   if(toast){
    setTimeout(() => {
     setToast(false); 
    }, 5000);
   }
  }, [toast])
  

  const showToast = (type, message) => {
    setToast(true);
    setToastDetails({ type, message });
  };

  const hideToast = () => {
    setToast(false);
    setToastDetails({ type: "", message: "" });
  };

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  return (
    <LayoutContext.Provider
      value={{
        onOpenModal,
        showToast,
      }}
    >
      <div style={{ backgroundColor: "#f6f5f3" }}>
        {open && (
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{ modal: "grey-background" }}
            className="requestModal customModal"
          >
            <RequestDemoSection
              onCloseModal={onCloseModal}
              showToast={showToast}
            />
          </Modal>
        )}
      </div>
      {/* <Header onOpenModal={onOpenModal} /> */}

      {children}
      {/* <ContactUs />
      <Footer /> */}

      <div
        className="toast"
        style={{ position: "fixed", bottom: 10, right: 10 }}
        role="alert"
        ref={toastRef}
      >
        <div
          className={`toast-body  ${
            toastDetails?.type === "SUCCESS" ? "text-success" : "text-danger"
          }`}
        >
          {toastDetails?.message}
        </div>
      </div>
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
