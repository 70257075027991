import React, { useState, useEffect } from "react";
import "../styles/footer.scss";
import { submitContactDetails } from "../api/contactApi";
import useLayout from "../hooks/useLayout";
import _ from "lodash";


export default function ContactUs() {
  const { showToast } = useLayout();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitBtnDisable, setIsSubmitBtnDisable ] = useState(true);

  const resetForm = () => {
    setValues({});
    setErrors({});
  };
  
useEffect(() => {
  if(!_.isEmpty(values.phone) && !_.isEmpty(values.msg) && !_.isEmpty(values.name)){
    setIsSubmitBtnDisable(false)
  } else {
    setIsSubmitBtnDisable(true);
  }
}, [values])


  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    submitContactDetails(
      values,
      (data) => {
        console.log("success data is", data);
        showToast("SUCCESS", "Your response is submitted successfully.");
        resetForm()
      },
      (err) => {
        console.log("error data is", err);
        showToast("ERROR", "Something went wrong. Please try again later");
      }
    );
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));
  };

  const validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = "Full Name is required field";
    }
    if (!values.msg) {
      errors.msg = "Message is required field";
    }
    if (!values.phone) {
      errors.phone = "Phone Number is required field";
    } else if (values.phone.length < 10) {
      errors.phone = "number must be 10 characters";
    }
    return errors;
  };

  return (
    <>
      <div className="footer-wrapper">
        <section className="workforce-sec">
          <div className="workforce-Hd">Empowering India’s Workforce</div>
          <div className="container">
            <form onSubmit={handleSubmit} noValidate>
              <div className="row gx-5">
                <div>
                  <div className="formInput">
                    <label className={errors.name ? "is-danger" : ""}>
                      Your Name*
                    </label>
                    <input
                      id="name"
                      autoComplete="off"
                      type="text"
                      className="inputStyle"
                      required
                      onChange={handleChange}
                      value={values.name || ""}
                    />
                    {errors.name && <p className="is-danger">{errors.name}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="formInput">
                    <label>Email Address</label>
                    <input
                      id="email"
                      type="text"
                      className="inputStyle"
                      value={values.email || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="formInput">
                    <label className={errors.phone ? "is-danger" : ""}>
                      Phone Number*
                    </label>
                    <input
                      type="number"
                      id="phone"
                      className="inputStyle"
                      required
                      onChange={(event) => {
                        const val = event.target.value;
                        if(!val || val.length <= 10){
                          handleChange(event);
                        }
                      }}
                      value={values.phone || ""}
                    />
                    {errors.phone && (
                      <p className="is-danger">{errors.phone}</p>
                    )}
                  </div>
                </div>

                <div className="col">
                  <div className="formInput">
                    <label className={errors.msg ? "is-danger" : ""}>
                      Message*
                    </label>
                    <textarea
                      id="msg"
                      rows={4}
                      // type="text"
                      className="inputStyle-message"
                      value={values.msg || ""}
                      onChange={handleChange}
                    />
                    {errors.msg && <p className="is-danger">{errors.msg}</p>}
                  </div>
                  
                </div>
                <div className="button-demo">
                  <button type="submit" disabled={isSubmitBtnDisable}>Partner with Us</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}
