const host = process.env.HOST_API;
const apiToken = process.env.API_TOKEN;

export function submitContactDetails(params, successCallBack, failureCallBack) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-token", apiToken);
  myHeaders.append("Content-Type", "application/json");

  fetch(`${host}/api/app/feedback/add`, {
    method: "post",
    headers: myHeaders,
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (res.ok) return res.json();
      else {
        return Promise.reject(res);
      }
    })
    .then((data) => {
      successCallBack(data);
    })
    .catch((err) => {
      err.json().then((json) => {
        failureCallBack(err);
      });
    });
}

export function requestDemo(params, successCallBack, failureCallBack) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-token", apiToken);
  myHeaders.append("Content-Type", "application/json");

  fetch(`${host}/api/app/requestDemo`, {
    method: "post",
    headers: myHeaders,
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (res.ok) return res.json();
      else {
        return Promise.reject(res);
      }
    })
    .then((data) => {
      successCallBack(data);
    })
    .catch((err) => {
      err.json().then((json) => {
        failureCallBack(err);
      });
    });
}
