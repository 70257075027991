import React, { useState } from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import _ from "lodash";
import Logo from "../images/logo.svg";
import FooterLogo from "../images/footer-logo.svg";
import "../styles/header.scss";
import { isBrowser } from "../utils/common";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import { careerUrl, blogUrl, pageLink } from "../constants/linkConstant";
import useLayout from "../hooks/useLayout";
import useScrollDirection from "../hooks/useScrollDirection";

const Header = ({ location }) => {
  const [slideMenu, setSlideMenu] = useState(false);
  const { onOpenModal } = useLayout();
  const breakpoints = useBreakpoint();

  const scrollDirection = useScrollDirection();

  const shouldHideHeader = scrollDirection === "down";

  const changeSlide = (index) => {
    setSlideMenu(!slideMenu);
  };
  const urlPath = isBrowser() ? window?.location?.pathname : "";

  return (
    <header
      className={`main-header ${shouldHideHeader ? "scroll-active" : ""}`}
    >
      <div className="container">
        <div className="row">
          <Link to={pageLink.home} className="col-md-3">
            <img
              className="header-logo"
              src={breakpoints.xs || breakpoints.sm ? Logo : Logo}
              alt="logo"
            />
          </Link>
          <div
            className={slideMenu === true ? "burger active" : "burger"}
            id="burger"
            onClick={changeSlide}
          >
            <span class="burger-line bar1"></span>
            <span class="burger-line bar2"></span>
          </div>

          <nav
            className={
              slideMenu === true
                ? "nav-wrapper col-md-9 active"
                : "nav-wrapper col-md-9"
            }
          >
            <ul className="pa0 ma0 list">
              <li>
                <Link
                  // className={urlPath === `/` ? "active" : ""}
                  activeClassName="active"
                  to="/"
                  onClick={changeSlide}
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  // className={urlPath.includes("/about") ? "active" : ""}
                  to={pageLink.about}
                  activeClassName="active"
                  onClick={changeSlide}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active"
                  // className={urlPath === "/our-solutions/" ? "active" : ""}
                  to={pageLink.ourSolutions}
                  onClick={changeSlide}
                >
                  Our Solutions
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active"
                  // className={urlPath.includes(pageLink.media) ? "active" : ""}
                  to={pageLink.media}
                  onClick={changeSlide}
                >
                  Media
                </Link>
              </li>
              <li>
                <Link
                  // activeClassName="active"
                  className={urlPath.includes("blog") ? "active" : ""}
                  to={blogUrl}
                  onClick={changeSlide}
                  target="_blank"
                >
                  Blog
                </Link>
              </li>
              {/* <li>
                <Link
                  // activeClassName="active"
                  className={urlPath.includes("careers") ? "active" : ""}
                  to={careerUrl}
                  target="_blank"
                  onClick={changeSlide}
                >
                  Careers
                </Link>
              </li> */}
              <li>
                <div
                  className="header-btn"
                  onClick={() => {
                    onOpenModal();
                    changeSlide();
                  }}
                >
                  Partner With Us
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
export default Header;
