import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/main.scss";
import ContactUs from "../components/contactUs";
import { LayoutProvider } from "../contexts/LayoutContext";

const MainLayout = ({ children }) => {
  return (
    <div>
      <LayoutProvider>
        <Header />
        <div className="main-container">
          {children}
          <ContactUs />
        </div>
        <Footer />
      </LayoutProvider>
    </div>
  );
};

export default MainLayout;
// export const LayoutPropConsumer = LayoutPropContext.Consumer
