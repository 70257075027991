import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/main.scss";
import ContactUs from "../components/contactUs";
import { LayoutProvider } from "../contexts/LayoutContext";

const DlLayout = ({ children }) => {
  return (
    <div>
      <LayoutProvider>
        <Header />
        <div className="main-container">
          {children}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default DlLayout;
// export const LayoutPropConsumer = LayoutPropContext.Consumer
