import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const requestDemoFormValidator = Yup.object().shape({
    fullName: Yup.string().required("This is required field"),
    companyName : Yup.string().required("This is required field"),
    email: Yup.string().required("This is required field").email("Please enter valid email"),
    phone: Yup.string().required('This is required field').matches(phoneRegExp, 'Phone number is not valid'),
    hearFrom :Yup.string().required("This is required field").max(500, "Message should not exceed 500 characters"),
    employeesCount:Yup.string().required("This is required field")
});


