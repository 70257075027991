import React, { useState, useEffect } from "react";
import Input from "./elements/Input";
import Button from "./elements/Button";
import { Formik } from "formik";

import "../styles/_requestDemo.scss";

// import LayoutContext from "../../Context/LayoutContext";
import * as yupVaidators from "./elements/requestDemoYupValidator";
import { requestDemo } from "../api/contactApi";
import _ from "lodash";

const employeeCountOptions = [
  {
    label: "1-10",
    value: "1-10",
  },
  {
    label: "11-25",
    value: "11-25",
  },
  {
    label: "26-50",
    value: "26-50",
  },
  {
    label: "51-100",
    value: "51-100",
  },
  {
    label: "101-500",
    value: "101-500",
  },
  {
    label: "501-1000",
    value: "501-1000",
  },
  {
    label: "1000+",
    value: "1000+",
  },
];
const howOptions = [
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "News",
    value: "News",
  },
  {
    label: "Partners",
    value: "Partners",
  },
  {
    label: "Others",
    value: "Others",
  },
];

const RequestDemoSection = (props) => {
  // const layoutContext = useContext(LayoutContext);
  const { onCloseModal, showToast } = props;

  const handleSubmit = (values, resetForm, setSubmitting) => {
    onCloseModal();
    requestDemo(
      values,
      (data) => {
        console.log("success data is", data);
        resetForm();
        props.onCloseModal();
        setSubmitting(false);
        showToast("SUCCESS", "Your response is submitted successfully.");
      },
      (err) => {
        console.log("error data is", err);
        showToast("ERROR", "Something went wrong. Please try again later");
        setSubmitting(false);
      }
    );
  };

  return (
    <section {...props}>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          phone: "",
          hearFrom: "",
          companyName: "",
          employeesCount: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, resetForm, setSubmitting);
          // setTimeout(() => {
          //   setSubmitting(false);
          // }, 500);
        }}
        validationSchema={yupVaidators.requestDemoFormValidator}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          console.log("form values are", values);
          return (
            <div className="grey-background">
              <h2 className="requestDemoHeader ta-c">Partner With Us</h2>
              <form
                className="contact-form requestDemoParagraph"
                onSubmit={handleSubmit}
              >
                <div className="requestdemo-full-width">
                  <Input
                    label={"Full Name*"}
                    type="text"
                    name="fullName"
                    onChange={handleChange("fullName")}
                    value={values.fullName}
                    onBlur={handleBlur("fullName")}
                    isError={touched["fullName"] && errors["fullName"]}
                    hint={touched["fullName"] && errors["fullName"]}
                  />
                </div>

                <div className="requestdemo-full-width">
                  <Input
                    label={"Company Name*"}
                    type="text"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                    onBlur={handleBlur("companyName")}
                    isError={errors["companyName"] && touched["companyName"]}
                    hint={touched["companyName"] && errors["companyName"]}
                  />
                </div>
                <div className="requestdemo-full-width">
                  <Input
                    label={"Number of employees*"}
                    name="employeesCount"
                    selectOption
                    selectOptions={employeeCountOptions}
                    value={values.employeesCount}
                    onChange={handleChange("employeesCount")}
                    onBlur={handleBlur("employeesCount")}
                    isError={
                      touched["employeesCount"] && errors["employeesCount"]
                    }
                    hint={touched["employeesCount"] && errors["employeesCount"]}
                  />
                </div>
                <div className="requestdemo-full-width">
                  <Input
                    label={"Mobile Number*"}
                    type="number"
                    name="phone"
                    value={values.phone}
                    onChange={(e) =>
                      e.target.value?.length <= 10 && handleChange(e)
                    }
                    onBlur={handleBlur("phone")}
                    isError={touched["phone"] && errors["phone"]}
                    hint={touched["phone"] && errors["phone"]}
                  />
                </div>
                <div className="requestdemo-full-width">
                  <Input
                    label={"Email*"}
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    isError={touched["email"] && errors["email"]}
                    hint={touched["email"] && errors["email"]}
                  />
                </div>

                <div className="requestdemo-full-width">
                  <Input
                    label="How did you hear about us?"
                    name="hearFrom"
                    selectOption
                    selectOptions={howOptions}
                    componentNeed="false"
                    value={values.hearFrom}
                    onChange={handleChange("hearFrom")}
                    onBlur={handleBlur("hearFrom")}
                    isError={touched["hearFrom"] && errors["hearFrom"]}
                    hint={touched["hearFrom"] && errors["hearFrom"]}
                  />
                </div>

                <div id="contact-submit">
                  {isSubmitting ? (
                    <div className="loader" />
                  ) : (
                    <Button
                      type="submit"
                      disabled={
                        !(
                          values.phone &&
                          values.hearFrom &&
                          values.email &&
                          values.employeesCount &&
                          values.companyName &&
                          values.fullName
                        )
                      }
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </section>
  );
};
export default RequestDemoSection;
